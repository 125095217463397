.contacts-page .col-md-4 {
  text-align: center;
  color: orange;
  padding: 20px;
}

.contacts-page .socials-container {
  padding: 0 10vw;
}

.contacts-page .action-icon {
  height: 50px;
  margin: 10px;
}

.contacts-page a {
  color: orange;
}
