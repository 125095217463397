.greeting-container {
  height: calc(100vh - 60px);
  min-height: 150px;
  position: relative;
  transition: all 1.5s ease-out;
  background-size: 600% 600%;
  text-align: center;
  position: relative;
  padding-top: 22vh;

  background: linear-gradient(
    50deg,
    #96fbc4,
    #f9f586,
    #57f2cc,
    #8fd3f4,
    #fed6e3,
    #fddb92,
    #84fab0
  );
  background-size: 600% 600%;
  /* background-attachment: fixed; */
  -webkit-animation: background-gradient-animation 70s ease infinite;
  -moz-animation: background-gradient-animation 70s ease infinite;
  animation: background-gradient-animation 70s ease infinite;
}

@-webkit-keyframes background-gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes background-gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes background-gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

@media only screen and (min-width: 610px) {
  .greeting-container {
    min-height: 170px;
  }
}

@media only screen and (min-width: 800px) {
  .greeting-container {
    min-height: 210px;
  }
}

.greeting-title {
  background: rgba(255, 255, 255, 0.7);
  font-family: "Lobster", cursive;
  padding: 10px 0;
  margin: 0 auto;
  width: 70vw;
  border-radius: 10vw;
}

#title-text {
  transition: opacity 0.5s ease-out;
  font-size: 5vmax;
}

@media only screen and (max-width: 380px) {
  #title-text {
    font-size: 7vmin;
  }
}

.navigation-arrow-container {
  position: absolute;
  width: 100%;
  height: 30px;
  right: 25%;
  left: 50%;
  margin-left: -50%;
  transition: all 0.7s ease-out;
}

.navigation-arrow-container > div {
  display: inline-block;
  width: 12vmin;
  height: 12vmin;
  background: rgba(255, 255, 255, 0.5);
  border: 1px solid silver;
  border-radius: 120%;
  cursor: pointer;
}

.triangle-icon {
  display: inline-block;
  border: solid rgba(65, 130, 130, 0.8);
  border-width: 0 1vmin 1vmin 0;
  padding: 2.7vmin;

  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);

  position: relative;
  top: 1.5vmin;
}

@-webkit-keyframes background-gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@-moz-keyframes background-gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}
@keyframes background-gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.about-img {
  display: block;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 5px;
  width: 100%;
  height: 100%;
}

@media only screen and (max-width: 995px) {
  .about-img {
    height: 50vh;
  }
}

.about-layout {
  margin-top: 15px;
}

.about-layout .row {
  margin-bottom: 10px;
}

.about-layout .col-lg-8 {
  padding-left: 12px;
  text-align: justify;
  text-justify: inter-character;
  font-family: Lobster;
}

.about-layout h1 {
  color: #ff6f69;
  font-size: 3.5vmax;
}

.about-layout p {
  color: #53ba83;
  font-size: 26px;
  padding-right: 3vw;
}
