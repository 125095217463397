.nav-item {
  padding: 8px;
  color: white;
  vertical-align: middle;
}

.nav-item a {
  color: white;
}

.nav-item a:hover {
  color: white;
}
