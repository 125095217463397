.card {
  width: 100%;
  height: 100%;
}

.card-img-top {
  width: 100%;
  height: 11vw;
  min-height: 150px;
  object-fit: cover;
}

.card-header {
  padding: 0;
  height: 11vw;
  min-height: 150px;
}


.card-header div:only-child {
  height: 100%;
}

.card-title {
  margin-bottom: 0.3rem;
}

.progress {
  margin-bottom: 5px;
}

.card p {
  margin-bottom: 0.2rem;
}

#paypal-button {
  width: 100%;
}

.card-body {
  padding-bottom: 0.1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.amount-input-col {
  margin: 0 5px 5px 0;
}

.amount-input-col input {
  width: 100%;
  height: 95%;
  min-width: 50px;
}
