.gallery-page .gallery-folder {
  display: block;
  border-radius: 1.8vw;
  width: 100%;
  opacity: 0.95;
  text-align: center;
  padding: 20vh 0 20vh 0;
  margin: 15px 0 7px 0;
  position: relative;
  transition: .2s;
}

.gallery-page .gallery-folder:hover {
  opacity: 1;
  transform: scale(1.01);
}

.gallery-page a {
  color: white;
  font-size: 7vh;
  font-weight: 500;
}

.gallery-page a:hover {
  color: white;
  text-decoration: none;
}
