.socials-container {
  text-align: center;
  margin: 10px auto;
  padding: 0 10px;
  display: flex;
  justify-content: space-between;
}

.social {
  display: inline-block;
  border-radius: 50%;
  text-align: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: .9;
}

.social:hover {
  opacity: 1;
}

.social-link {
  width: 18%;
}
