.friends-page img {
  max-width: 500px;
  width: 100%;
}

.friends-page .col-md-6 {
  display: table;
  height: 350px;
  text-align: center;
  padding: 1%;
}

.friends-page a {
  display: table-cell;
  vertical-align: middle;
}
