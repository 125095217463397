.news-layout {
  margin-top: 15px;
}

.news-layout .row {
  margin-bottom: 10px;
}

.news-layout .col-lg-6, .news-layout .col-lg-3 {
  padding-left: 12px;
  text-align: justify;
  text-justify: inter-character;
  font-family: Lobster;
  min-height: 220px;
}

.news-layout h1 {
  color: #ff6f69;
  font-size: 2.7vmax;
}

.news-layout p {
  color: #53ba83;
  font-size: 24px;
  padding-right: 3vw;
}

.link-paragraph {
  color: #ffa600;
  font-size: 32px;
  padding-right: 3vw;
}

.news-img {
  display: block;
  border: 1px solid rgb(200, 200, 200);
  border-radius: 5px;
  width: 100%;
  height: 100%;
  min-height: 200px;
}

.announcement-paragraph {
  color: #53ba83;
  font-size: 24px;
  font-weight: 50;
  padding-right: 3vw;
}
