.donation-modal table {
  text-align: center;
}

.donation-modal table td {
  vertical-align: middle;
}

.donation-modal .epay-amount-input {
  width: 75px;
  display: inline;
  vertical-align: middle;
}
