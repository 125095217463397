.home-projects-container {
  background: rgba(255, 200, 140, 0.8);
  padding: 20px 20px 40px 20px;
}

.headline {
  font-size: 4.4vmax;
  font-family: "Lobster", cursive;
  text-align: center;
  margin: 3.8vh 0 5vh 0;
}

@media only screen and (max-width: 768px) {
  .headline {
    font-size: 5.4vmax;
  }
}

/* Gallery */
.col-lg-4 {
  padding: 0 5px 5px 5px;
  transition: .15s;
}

/* Project cards */

.project-card {
  cursor: pointer;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
   -khtml-user-select: none; /* Konqueror HTML */
     -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
          user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */
  
  transition: .2s;
}

.project-card:hover {
  transform: scale(1.01);
}

.h5 {
  font-size: calc(10px + 1.15vmax);;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  /* margin: 10px; */
}

.col {
  padding: 0;
}

.row {
  margin: 0;
}

.projectsColumn {
  padding: 5px 5px 0px 5px;
}

/* *** *** *** */
.main-goal {
  padding: 5px;
  font-size: 22px;
}
